:root {
    --font-family-default: Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial,
      sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
      "Noto Color Emoji";
    --primary-blue: #3173e6;
    --primary-green: #5baaa2;
    --white: #FFFFFF;
    --black: #000000;
    --bg-black: #040e13;
    --border-color-grey: #E4E4E4;
    --bg-dark-blue: #021d30;
    --text-paragraph-light: #6c6c6c;
    --text-paragraph: #000000;
    --table-header-color: #666666;
    --table-data-color: #707070;
    --spinner-gray: #d3d3d3;
    --bg-light-green: rgba(197, 236, 232, 0.2);
    --bg-overlay: rgba(2, 29, 48, 0.8);
    --border-radius-btn: 6px;
    --border-radius-lg: 20px; // .card, phone number pin div
    --border-radius-xl: 25px; // status bar
    --box-shadow-above:  0 -3px 14px #ccccccc4;
    --box-shadow-below:  0 3px 14px #ccccccc4;
  }
