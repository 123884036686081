body {
    font-family: var(--font-family-default);
    background: var(--white);
    font-size: 16px;
    line-height: 18.75px;

    @include size(md-and-up) {
        background: var(--bg-dark-blue);
    }
}

p {
    font-size: 14px;
    line-height: 20px;

    @include size(sm-and-up) {
        font-size: 16px;
    }
}

a {
    color: var(--primary-blue);
    text-decoration: none;
}

.card {
    padding: 0;
    border-radius: var(--border-radius-lg);
}

h1,
.h1 {
    color: var(--primary-green);
    font-style: normal;
    font-weight: 300;
    font-size: 40px;
    line-height: 50px;

    @include size(md-and-up) {
        font-size: 50px;
        line-height: 65px;
    }
}

// The main content card is full viewport width & height on mobile
// It only looks like a card at "md-and-up"
.main-content.card {
    width: 307px;
    width: 100%;
    height: calc(100vh - 62.34px);
    display: flex;
    align-items: center;
    background: transparent;
    border-color: transparent;

    @include size(md-and-up) {
        margin-top: 60px;
        margin-bottom: 50px;
        width: 728px;
        height: unset;
        background: var(--white);
    }
    @include size(lg-and-up) {
        width: 824px;
    }
    @include size(xl-and-up) {
        width: 964px;
    }
}

.spinner {
    &-background {
        height: 100%;
        width: 100%;
        opacity: 0.5;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding-top: 33vh;
    
        @include size(md-and-up) {
            min-height: 487px; // = height of landing page card
            padding: 0;
            align-items: center;
        }
    }

    &-border {
        color: var(--spinner-gray);
    }
}