@mixin size($size) {
    @if $size == xs {
      @media (max-width: 575px) {
        @content;
      }
    } @else if $size == sm-and-up {
      @media (min-width: 576px) {
        @content;
      }
    } @else if $size == md-and-up {
      @media (min-width: 768px) {
        @content;
      }
    } @else if $size == lg-and-up {
      @media (min-width: 992px) {
        @content;
      }
    } @else if $size == xl-and-up {
      @media (min-width: 1200px) {
        @content;
      }
    }
  }

  @mixin text-large {
    font-weight: 300;
    font-size: 40px;
    line-height: 50px;
    text-align: center;
}

@mixin top-section-styles {
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;

    @include size(md-and-up) {
        height: unset;
    }
}