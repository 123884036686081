cr-avid-landing-page,
cr-avid-landing-page-generic {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.landing-page {
    width: 100%;

    &__body {
        max-width: 100vw;
        padding: 40px 15px 70px 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;

        @include size(sm-and-up) {
            padding: 30px;
        }

        .welcome-generic {
            width: 100%;

            @include size(md-and-up) {
                padding-top: 22px;
            }

            &__body {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                max-width: 750px;
                margin: auto;
                padding-top: 20px;

                @include size(md-and-up) {
                    flex-direction: row;
                    padding-top: 27px;
                }

                &__column {
                    max-width: 400px;

                    @include size(md-and-up) {
                        max-width: 317px;
                    }
                    @include size(lg-and-up) {
                        max-width: 340px;
                    }
                }

                p {
                    line-height: 19px;
                }
            }
        }

        button {
            min-width: 140px;
        }
    }

    &__footer {
        padding: 30px 30px 160px 30px;
        width: 100%;
        display: flex;
        justify-content: center;

        @include size(sm-and-up) {
            padding-bottom: 220px;
        }
        @include size(md-and-up) {
            padding-bottom: 40px;
        }

        &-content {
            width: 100%;
            max-width: 353px;
            display: flex;
            justify-content: space-between;
            flex-direction: column;

            @include size(md-and-up) {
                flex-direction: row;
                max-width: 692px;
            }

            p,
            address {
                font-size: 14px;
                line-height: 20px;
            }

            .employee-info {
                max-width: 310px;
                min-height: 60px;
            }

            img.footer-icon {
                margin: 5px 15px 0 0;
                height: 29px;
            }

            .footer-agent-info-label {
                font-size: 10px;
                font-weight: 900;
                line-height: 12px;
                color: var(--black);
                margin-bottom: 5px;
            }
        }

        &-content--generic {
            width: 100%;
            max-width: 710px;
            padding-top: 12px;
            display: flex;
            flex-direction: row;
            justify-content: center;

            @include size(md-and-up) {
                padding-top: 0;
            }

            img.footer-icon {
                margin-right: 15px;
                height: 29px;
            }

            p {
                font-size: 14px;
                line-height: 25px;
                margin-bottom: 0;
            }
        }
    }

    &__go-back {
        position: absolute;
        padding: 1rem 2rem;

        @include size(md-and-up) {
            top: 50%;
            transform: translateY(-50%);
            margin-left: 3%;
            padding: unset;
        }
    }
}
