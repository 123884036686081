.color-primary-blue {
    color: var(--primary-blue);
}

.color-primary-green {
    color: var(--primary-green);
}

.background-primary-blue {
    background: var(--primary-blue);
}

.fs-10px {
    font-size: 10px;
}

.fs-12px {
    font-size: 12px;
}

.fs-14px {
    font-size: 14px;
}

.color-black {
    color: var(--black);
}

.color-grey {
    color: var(--text-paragraph-light);
}

.max-w-310px {
    max-width: 310px;
}

.box-shadow-above {
    box-shadow: var(--box-shadow-above);
}

.box-shadow-below {
    box-shadow: var(--box-shadow-below);
}
